import { createSlice } from "@reduxjs/toolkit";
import { saveToSessionStorage } from "../../utils/storage";
// import { getSessionStorageItem, setSessionStorageItem } from "../../utils/sessionStorageUtils";

const initialState = {
  gameMode: null,
  gameType: null,
  gameObj: {
    players: [],
  },
  rooms: [],
  roomType: "silverRoom",
  roomAmount: 0,
  isConnected: false,
  roomProperties: null,
  userData: null,
  game: {},
  passedTime: null, // for store the turn time on reload
  connections: 0,
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setPassedTime: (state, action) => {
      state.passedTime = action.payload;
    },
    setisConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    setRoomType: (state, action) => {
      state.roomType = action.payload;
      saveToSessionStorage("roomType", state.roomType);
    },
    setGameMode: (state, action) => {
      state.gameMode = action.payload;
      saveToSessionStorage("gameMode", state.gameMode);
    },
    setRoomAmount: (state, action) => {
      state.roomAmount = action.payload;
      saveToSessionStorage("roomAmount", state.roomAmount);
    },

    setGamePlayers: (state, action) => {
      state.game.players = action.payload;
    },

    setRoomProperties: (state, action) => {
      state.roomProperties = action.payload;
    },
    setUserData: (state, action) => {
      const payload = action.payload;
      if (payload === null) {
        state.userData = null;
        return;
      }

      state.userData = {
        ...state.userData,
        ...payload,
      };
    },
    setGame: (state, action) => {
      let isEmptyObj = Object.keys(action.payload).length === 0 ? true : false;

      if (isEmptyObj) {
        state.game = action.payload;
      } else {
        let players = action.payload.players;
        let userId = localStorage.getItem("userID");
        let userIndex = players.findIndex((player) => player.userID === userId);
        state.userData = players[userIndex];
        state.game = action.payload;
        state.roomType = action.payload.roomType;
        state.connections =
          players.filter((player) => player.status === "ACTIVE")?.length || 0;
      }
    },
  },
});

export default gameSlice.reducer;
export const {
  setisConnected,
  setRoomType,
  setGameMode,
  setGamePlayers,
  setUserData,
  setRoomAmount,
  setRoomProperties,
  setGame,
  setPassedTime,
} = gameSlice.actions;
