import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import BlackjackLayout from "src/layouts/BlackjackLayout";
import MainLayout from "./views/pages/Ludo/Layout/MainLayout";
import { ChessLayout } from "./views/pages/Chess/ChessLayout";
import { LudoLayout } from "./views/pages/Ludo/LudoLayout";
import { TeenPattiLayout } from "./views/pages/TeenPatti/TeenPattiLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/index")),
  },
  {
    exact: true,
    path: "/transaction",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Home/Transaction")),
  },
  {
    exact: true,
    path: "/view-transaction",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Home/Transaction")),
  },
  {
    exact: true,
    path: "/admin-dashboard",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Admin/H2hDashboard")),
  },
  {
    exact: true,
    path: "/admin-spin",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Admin/Spin/Spin")),
  },
  {
    exact: true,
    path: "/lottery-admin",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Lottery/Admin/index")),
  },
  // {
  //   exact: true,
  //   path: "/derby-admin",
  //   layout: DashboardLayout,
  //   guard: true,
  //   component: lazy(() => import("src/component/DerbyAdmin")),
  // },
  {
    exact: true,
    path: "/create-profile",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Profile/CreateProfile")),
  },

  {
    exact: true,
    path: "/my-profile",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Profile/MyProfile")),
  },
  {
    exact: true,
    path: "/referral",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ReferralSection/Referral")),
  },
  {
    exact: true,
    path: "/revenue",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ReferralSection/Revenue")),
  },

  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/jackpot",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Jackpot/Jackpot")
    ),
  },
  // {
  //   exact: true,
  //   path: "/aboutUs",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/StaticContent/PrivacyPolicy")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/terms-condition",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/StaticContent/PrivacyPolicy")
  //   ),
  // },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticContent/AboutUs")),
  },
  {
    exact: true,
    path: "/terms-condition",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/TermsConditions")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/PolicyPrivacy")
    ),
  },
  {
    exact: true,
    path: "/features",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Features")),
  },

  {
    exact: true,
    path: "/h2h",
    layout: HomeLayout,
    // guard: true,
    component: lazy(() => import("src/views/pages/H2H/Banner")),
  },

  {
    exact: true,
    path: "/derby",
    layout: HomeLayout,
    // guard: true,
    component: lazy(() => import("src/views/pages/Derby/MainTabSection")),
  },
  {
    exact: true,
    path: "/race-view",
    // layout: HomeLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Derby/LinkUnityCode")),
  },
  {
    exact: true,
    path: "/leaderboard",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Derby/index")),
  },

  {
    exact: true,
    path: "/blackjack-leaderboard",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/BlackJack/BlackJackLeaderboard")
    ),
  },
  // {
  //   exact: true,
  //   path: "/howtoplay",
  //   layout: HomeLayout,
  //   guard: true,
  //   component: lazy(() => import("src/views/pages/Howtoplay")),
  // },

  {
    exact: true,
    path: "/how-to-play",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/BlackJack/Howtoplay")),
  },
  {
    exact: true,
    path: "/learnmore",
    layout: HomeLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/ConnectWallet")),
  },
  {
    exact: true,
    path: "/lottery",
    layout: HomeLayout,
    // guard: true,
    component: lazy(() => import("src/views/pages/Lottery/Home")),
  },

  {
    exact: true,
    path: "/black-jack",
    layout: BlackjackLayout,
    // guard: true,
    component: lazy(() => import("src/views/pages/BlackJack/GameRoomNew")),
  },

  {
    exact: true,
    path: "/category-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/Category/CategoryTable")
    ),
  },
  {
    exact: true,
    path: "/referral-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/ReferralManagement/ReferralManagement")
    ),
  },
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/UserManagement/UserManagement")
    ),
  },
  {
    exact: true,
    path: "/fraud-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/FraudManagement/FraudManagement")
    ),
  },
  {
    exact: true,
    path: "/wallet-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/WalletManagement/WalletManagement")
    ),
  },
  {
    exact: true,
    path: "/add-category",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Admin/Category/AddCategory")),
  },
  {
    exact: true,
    path: "/add-question",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/QuestionManag/AddQuestion")
    ),
  },
  {
    exact: true,
    path: "/question-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/QuestionManag/QuestionManagement")
    ),
  },
  {
    exact: true,
    path: "/h2h-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Admin/AdminDashboard")),
  },
  {
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/StaticContentManagement/StaticManagement")
    ),
  },
  {
    exact: true,
    path: "/add-faq",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Admin/FaqManagement/AddFaq")),
  },
  {
    exact: true,
    path: "/edit-static",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Admin/FaqManagement/AddFaq")),
  },
  {
    exact: true,
    path: "/faq-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/FaqManagement/FaqList")
    ),
  },
  {
    exact: true,
    path: "/game-play-reminders",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/GamePlayReminders/List")
    ),
  },
  {
    exact: true,
    path: "/add-game-play",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Admin/GamePlayReminders/Add")
    ),
  },
  {
    exact: true,
    path: "/ludo",
    // guard: true,
    layout: LudoLayout,
    component: lazy(() =>
      import("src/views/pages/Ludo/screens/splashScreen/Splash")
    ),
  },

  {
    exact: true,
    path: "/ludo/gameEnter",
    // guard: true,
    layout: LudoLayout,
    component: lazy(() =>
      import("src/views/pages/Ludo/screens/splashScreen/EnterScreen")
    ),
  },

  {
    exact: true,
    path: "/ludo/menu",
    layout: LudoLayout,
    // guard: true,
    component: MainLayout,
  },

  {
    exact: true,
    path: "/ludo/game",
    layout: LudoLayout,
    // guard: true,
    component: lazy(() => import("src/views/pages/Ludo/screens/main/MainGame")),
  },
  {
    exact: true,
    path: "/chess",
    // guard: true,
    layout: ChessLayout,
    component: lazy(() =>
      import("src/views/pages/Chess/Pages/screen/SplashScreen")
    ),
  },

  {
    exact: true,
    path: "/chess/menu",
    // guard: true,
    layout: ChessLayout,
    component: lazy(() =>
      import("src/views/pages/Chess/Main")
    ),
  },

  {
    exact: true,
    path: "/chess/privateRoom",
    layout: ChessLayout,
    // guard: true,
    component: lazy(() =>
      import("src/views/pages/Chess/Pages/MainPage/PrivateRoom")
    ),
  },

  {
    exact: true,
    path: "/chess/game/:id",
    layout: ChessLayout,
    // guard: true,
    component: lazy(() => import("src/views/pages/Chess/ChessBoard/GameApp")),
  },
  {
    exact: true,
    path: "/teenpatti",
    // guard: true,
    layout: TeenPattiLayout,
    component: lazy(() =>
      import("src/views/pages/TeenPatti/components/screens/SplashScreen")
    ),
  },
  {
    exact: true,
    path: "/teenpatti/home",
    // guard: true,
    layout: TeenPattiLayout,
    component: lazy(() =>
      import("src/views/pages/TeenPatti/pages/Home.jsx")
    ),
  },
  {
    exact: true,
    path: "/teenpatti/game",
    // guard: true,
    layout: TeenPattiLayout,
    component: lazy(() =>
      import("src/views/pages/TeenPatti/pages/Game.jsx")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
