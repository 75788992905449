import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import NavItem from "./NavItem";
import { FaTelegramPlane } from "react-icons/fa";
import { AuthContext } from "src/context/Auth";
import ContactUsModal from "src/component/ContactUsModal";
import {
  AiFillInstagram,
  AiFillPhone,
  AiOutlineWhatsApp,
} from "react-icons/ai";

const sections = [
  {
    items: [
      {
        title: "Home",
        href: "/",
        img: "images/sideicon.png",
        title1: "Welcome",
      },
      // {
      //   title: "Poker",
      //   href: "/transaction",
      //   img: "images/sideicon_1.png",
      //   title1: "card game",
      // },

      {
        title: "Black Jack",
        href: "/black-jack",
        img: "images/sideicon_2.png",
        title1: "card game",
      },
      {
        title: "Lottery",
        href: "/lottery",
        img: "images/sideicon_3.png",
        title1: "luck at guessing",
      },
      {
        title: "Derby Game",
        href: "/derby",
        img: "images/sideicon_4.png",
        title1: "Racing game",
      },
      {
        title: "H2H Betting",
        href: "/h2h",
        img: "images/sideicon_5.png",
        title1: "Fortnite quiz",
      },
      {
        title: "The Ludo Club",
        href: "/ludo",
        img: "images/sideicon_6.png",
        title1: "Board Game",
      },
      {
        title: "The Chess Club",
        href: "/chess",
        img: "images/sideicon_7.png",
        title1: "Board Game",
      },
      {
        title: "Spin",
        href: "/jackpot",
        img: "images/sideicon_8.png",
        title1: "Casino Game",
      },
    ],
  },
];
const AdminSection = [
  {
    items: [
      {
        title: "Dashboard",
        href: "/admin-dashboard",
        img: "images/sideicon.png",
      },
      {
        title: "User Management",
        href: "/user-management",
        img: "images/sideicon.png",
      },
      {
        title: "Fraud Management",
        href: "/fraud-management",
        img: "images/sideicon.png",
      },
      {
        title: "Wallet Management",
        href: "/wallet-management",
        img: "images/sideicon.png",
      },
      {
        title: "Referral Management",
        href: "/referral-management",
        img: "images/sideicon.png",
      },
      {
        title: "H2H Management",
        href: "/h2h-management",
        img: "images/sideicon_5.png",
      },
      {
        title: "Static Content Management",
        href: "/static-management",
        img: "images/sideicon_5.png",
      },
      {
        title: "Spin Management",
        href: "/admin-spin",
        img: "images/sideicon_5.png",
      },
      {
        title: "FAQ's Management",
        href: "/faq-management",
        img: "images/sideicon_5.png",
      },
      {
        title: "Game Play Reminders",
        href: "/game-play-reminders",
        img: "images/sideicon_5.png",
      },
      {
        title: "Lottery Management",
        href: "/lottery-admin",
        img: "images/lotteryicon.png",
      },
      // {
      //   title: "Derby Management",
      //   href: "/derby-admin",
      //   img: "images/horseicon.png",
      // },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  referal: {
    cursor: "pointer",
    padding: "0px 23px",
    background: "linear-gradient(105.04deg, #FFCA64 -4.56%, #CA772A 93.4%)",
    borderRadius: "100px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "& button": {
      color: "#fff !important",
      textTransform: "uppercase",
      fontSize: "14px",
      fontWeight: "500",
      padding: "10px 8px",
    },
    "@media(min-width:600px)": {
      margin: "0px 0 14px",
    },
  },
  referal1: {
    cursor: "pointer",
    margin: "0px 0 0px",
    padding: "0px 23px",
    background: "linear-gradient(105.04deg, #FFCA64 -4.56%, #CA772A 93.4%)",
    borderRadius: "100px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "& button": {
      color: "#fff !important",
      textTransform: "uppercase",
      fontSize: "14px",
      fontWeight: "500",
    },
    "@media(min-width:600px)": {
      display: "none",
    },
  },
  mobileDrawer: {
    width: 270,
    background: "#191B30",
  },
  desktopDrawer: {
    top: "0",
    width: "230px",
    height: "100%",
    background:
      "radial-gradient(38.69% 25.58% at 92.12% 21.4%, rgba(247, 148, 29, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #191B30",
    borderRight: "1px solid #2C2C2C",
    backdropFilter: "blur(3.22162px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  subSideBar: {
    padding: "0px 18px",
    "& h5": {
      fontSize: "14px",
      cursor: "pointer",
    },
    "& svg": {
      color: "#ffb000db",
      fontSize: "24px",
    },
    "& .bgProfileBox": {
      [theme.breakpoints.down("xs")]: {
        height: "auto",
        display: "inline",
        padding: "4px 7px 4px 5px",
        borderRadius: "10px",
        alignItems: "non",
        width: "100%",
        backgroundImage: "none",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        "& img": {
          width: "auto",
          maxWidth: "145px",
        },
      },
      "& h4": {
        fontSize: "12px",
        fontWeight: "300",
        marginBottom: "8px",
      },
    },
    "& .seeBox": {
      background: "#dac7c7",
      width: "19px",
      height: "19px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      marginLeft: "3px",
      cursor: "pointer",
      "& svg": {
        color: "rgba(30, 0, 12, 0.87)",
        fontSize: "13px",
      },
    },
  },
  rightImg: {
    // width: "calc(100% + 60px)",
    position: "absolute",
    left: "50%",
    top: "14px",
    maxWidth: "fit-content",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      top: "228px",
      display: "none",
    },
  },
  TopBoxes: {
    padding: "16px 18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 18px",
    },
    "& h5": {
      fontSize: "14px",
      cursor: "pointer",
      "@media(max-width:767px)": {
        fontSize: "14px !important",
      },
    },
    "& .randomStarBox": {
      backgroundColor: "rgba(255, 255, 255, 0.03)",
      width: "100%",
      maxWidth: "88px",
      padding: "5px 4px",
      borderRadius: "50px",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      "& p": {
        fontSize: "10px",
        "@media(max-width:767px)": {
          fontSize: "10px !important",
        },
      },
    },
  },
  positionClx: {
    width: "100%",
    position: "absolute",
    bottom: 16,
  },
  sideHeight: {
    "& .MuiList-padding": {
      paddingBottom: "47px !important",
    },
  },
  sideHeight1: {
    "& .MuiList-padding": {
      paddingBottom: "30px !important",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [contactModal, setContactModal] = useState(false);
  const checkUserType = localStorage.getItem("ADMIN") ? AdminSection : sections;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box padding={0} align="center">
        <Logo
          onClick={() => {
            localStorage.getItem("ADMIN") && localStorage.removeItem("ADMIN");
            history.push("/");
          }}
          className="logoDrawerBox"
          alt="Logo"
        />
      </Box>
      <Box mt={1}>
        <img
          src="images/lineborder.png"
          alt="borderImage"
          style={{ display: "none" }}
        />
      </Box>

      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          mb={2}
          className={
            !localStorage.getItem("ADMIN")
              ? classes.sideHeight
              : classes.sideHeight1
          }
        >
          {checkUserType?.map((section, i) => (
            <List
              className={
                !localStorage.getItem("ADMIN") ? "sideMenuBox" : "sideMenuBox1"
              }
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              <List disablePadding>
                {section?.items.map((item) => (
                  <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    img={item.img}
                    title1={item.title1}
                  />
                ))}
              </List>
            </List>
          ))}

          {!localStorage.getItem("ADMIN") && (
            <Box className={classes.positionClx}>
              <Box className={classes.subSideBar}>
                <Box className="bgProfileBox" align="center">
                  <Box
                    align="center"
                    className={classes.referal1}
                    onClick={() => {
                      const url = process.env.REACT_APP_FREE_URL;
                      const checkUrl = auth?.userData?.email
                        ? `${url}/?email=${auth?.userData?.email}`
                        : url;
                      window.open(checkUrl);
                    }}
                    style={{ marginBottom: "10px " }}
                  >
                    <Button>Switch to freeplay</Button>
                  </Box>
                  <Box
                    align="center"
                    className={classes.referal}
                    onClick={() => history.push("/referral")}
                  >
                    <Button>Referral</Button>
                  </Box>
                </Box>

                {/* <Hidden xsDown>
                  <Box align="center" mt={2} mb={0}>
                    <img
                      src="images/join_text.png"
                      alt="joint"
                      style={{ width: "auto", maxWidth: "150px" }}
                    />
                  </Box>
                </Hidden> */}
                <Box className="displayCenter socialresponBox">
                  <IconButton
                    href="https://t.me/+IK3LR5TPVhs1N2Fl"
                    target="_blank"
                    className="socialButton"
                  >
                    <FaTelegramPlane />
                  </IconButton>
                  <IconButton
                    href="https://instagram.com/betbyheart6?igshid=OGQ5ZDc2ODk2ZA=="
                    target="_blank"
                    className="socialButton"
                  >
                    <AiFillInstagram />
                  </IconButton>
                  <IconButton
                    href="https://www.whatsapp.com/channel/0029VaAbWMT0LKZ8fa1mev2j"
                    target="_blank"
                    className="socialButton"
                  >
                    <AiOutlineWhatsApp />
                  </IconButton>
                </Box>
              </Box>
              {/* <Box
                mt={2}
                style={{ cursor: "pointer" }}
                className="displayCenter"
                onClick={() => setContactModal(true)}
              >
                <AiFillPhone style={{ color: "orange" }} />
                &nbsp;
                <Typography variant="body2" className="contactusText">
                  Contact Us
                </Typography>
              </Box> */}
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
      <ContactUsModal openModal={contactModal} setOpenModal={setContactModal} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box>
          <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </Box>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
