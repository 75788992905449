import {io} from "socket.io-client";
import {getURL} from "../utils/url";
import {getData} from "./storage";

// options
const option = {
    closeOnBeforeunload: false,
};

// // console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);
// ** socket client instance
// export const URL = 'http://localhost:3004';
export const URL = 'https://chess.betbyheart.com';
const socket2 = io(URL, option);
// const URL = io('https://chess.betbyheart.com', option);



async function sendRequest(key, dataToSend, callback) {
  if(getData('isMultiplayer')) { 
    socket2.emit(key, dataToSend, callback)
  }
}

socket2.on("pong", function (ms) {
    console.log("latency", ms);
});

// // export
export {socket2, sendRequest};

export const onGameEvent = "onGameEvent";
export const CreateNewChessGame = "createNewGame";
export const GetLobbies = "getLobbies";
export const GetSessionData = "getSessionData";
export const PlayerJoinGame = "playerJoinGame";
export const PlayerJoinedRoom = "playerJoinedRoom";
export const SendTimer = "sendTimer";
export const OpponentMove = "opponentMove";
export const secondPlayeMove = "secondPlayeMove";
export const BotTurn = "botTurn";
export const NewMove = "newMove";
export const StartGame = "startGame";
export const onPlayerLeftRoom = "onPlayerLeftRoom";
export const endGame = "endGame";
export const offerDraw = "offerDraw";
export const takeBack = "takeBack";
export const undoRequestAccept = "undoRequestAccept";
export const gameSessionData = "gameSessionData";
export const restoreGame = "restoreGame";
export const playerRejoin = "playerRejoin";

export const BEST_MOVE = "BEST_MOVE";
export const USED_POWER_UP = "usedPowerUps";

export const JOIN_TOURNAMENT = "joinTournament";
export const LEAVE_TOURNAMENT = "leaveTournament";
export const END_TOURNAMENT = "endTournament";
export const START_TOURNAMENT = "startTournament";
export const PAIR_USER = "pairUser";
export const TOURNAMENT_START_TIME = "tournamentStartTime";
export const SWISS_PAIR_AGAIN = "swissPairAgain";
export const ROUND_COMPLETE = "roundComplete";
