import { publish } from "../CustomEvents/event";
import {
  OnConnectToMaster,
  OnLocalRoomListUpdate,
  OnPlayerEnterRoom,
  OnPlayerLeftRoom,
  OnPlayerList,
  OnRoomListing,
  OnRoomPropertiesChange,
  ScoketleaveRoom,
  SocketOnPlayerEnterRoom,
  SocketOnPlayerLeftRoom,
  SocketOnRoomListUpdate,
  SocketOnRoomPropertiesChange,
  SocketPlayerList,
  SocketRoomListing,
  SocketcreateRoom,
  SocketgetRoomProperties,
  SocketjoinRoom,
  SocketraiseEvent,
  SocketreJoinRoom,
  SocketsetRoomProperties,
  onCreateRoom,
  onGetRoomProperties,
  onJoinRoom,
  onJoinRoomFailed,
  onRaiseEvent,
} from "../constants/socketKeys";
import { socket } from "./socket";

export const socketEvents = () => {
  socket.on("connect", () => {
    //("client connection to this socket.id", socket.id);
    sessionStorage.setItem("isConnected", true);
    setTimeout(() => {
      publish(OnConnectToMaster, socket.id);
    }, 1000);
  });

  socket.on("disconnect", () => {
    // ('Socket.IO disconnected');
    // showToast("error", "Server Disconnected");
  });

  // ** on Create room
  socket.on(SocketcreateRoom, function (msg) {
    publish(onCreateRoom, msg);
    sessionStorage.setItem("isConnected", false);
  });

  // ** on Room Join
  socket.on(SocketjoinRoom, function (msg) {
    if (msg.success === false) {
      console.log("SocketjoinRoom from server:", msg);
      publish(onJoinRoomFailed, msg);
    } else {
      publish(onJoinRoom, msg);
    }
  });

  // ** on Re-Room Join
  socket.on(SocketreJoinRoom, function (msg) {
    if (msg.success === false) {
      publish(onJoinRoomFailed, msg);
    } else {
      publish(onJoinRoom, msg);
    }
  });

  //** on player left room */
  socket.on(SocketOnPlayerLeftRoom, function (msg) {
    publish(OnPlayerLeftRoom, msg.data);
  });

  //** on player leave room */
  socket.on(ScoketleaveRoom, function (msg) {
    // showToast('info',"Player leave the room");
    // //('ScoketleaveRoom from server:', msg);
  });

  // ** GET ROOM LISTING
  socket.on(SocketRoomListing, function (msg) {
    publish(OnRoomListing, msg);
  });

  // ** GET PLAYER LISTING OF LOCAL PLAYER
  socket.on(SocketPlayerList, (players) => {
    publish(OnPlayerList, players);
  });

  // **  GET ROOM PROPERTIES
  socket.on(SocketgetRoomProperties, (data) => {
    publish(onGetRoomProperties, data);
  });

  //** ON PLAYER ENTER ROOM */
  socket.on(SocketOnPlayerEnterRoom, function (msg) {
    publish(OnPlayerEnterRoom, msg.data);
  });

  //** ON ROOM PROPERTY CHANGE */
  socket.on(SocketOnRoomPropertiesChange, function (msg) {
    publish(OnRoomPropertiesChange, msg);
  });

  // ** ON SOCKET RAISE EVENT
  socket.on(SocketraiseEvent, function (msg) {
    if (msg.success === false) {
      return;
    }
    publish(onRaiseEvent, msg);
  });

  // ON ROME LIST UPDATE
  socket.on(SocketOnRoomListUpdate, (data) => {
    publish(OnLocalRoomListUpdate);
  });
};
