import { io } from "socket.io-client";

// options
const option = {
  closeOnBeforeunload: false,
};

// have to use ENVCMD for import // import.meta.env.VITE_SOCKET_URL,
// ** socket client instance
// const socket = io('https://teenpattisocket.betbyheart.com/', option);

// let local_socket_url = "http://localhost:3008";
let local_socket_url = "http://teenpattisocket.betbyheart.in";

const socket = io(local_socket_url, option);

async function sendRequest(key, dataToSend, callback) {
  socket.emit(key, dataToSend, callback);
}

// export
export { socket, sendRequest };
